import _fittings from "fittings";
var exports = {};
var module = {
  exports: exports
};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

_nullRequire.resolve = _nullRequire;
var Fittings = _fittings; //
//
//

Fittings.extend({
  library: [{
    path: "react",
    expose: "react"
  }, {
    path: "./reacts",
    expose: "reacts"
  }]
}).on(module);
export default module.exports;